import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { isEDMode, getNested } from '@src/helper';

// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const ResubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            }
        }
    }

    useEffect(()=>{
                //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect

        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {

            navigate('/registration');
        }
    },[])


    return (
        <Layout addedClass="page--resubscribe-confirmed">
            <Seo title="Thank You for Resubscribing  | LUPKYNIS&reg; (voclosporin)" description="" robots="noindex" canonicalURL="https://www.lupkynis.com/resubscribe-confirmed" />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">Welcome back!</h1>
                            <h2>We're happy to see you again</h2>
                            <p>{getNested(location,'state', 'values', 'email')}, you've successfully resubscribed to the following:</p>
                            {(getNested(location,'state', 'values', 'unsubscribeAuriniaAlliance') || getNested(location,'state', 'values', 'unsubscribeDiseaseAwareness') || getNested(location,'state', 'values', 'unsubscribeCorporate')) && <p>You have also chosen to resubscribe to the following:</p>}
                            <ul>
                                {getNested(location,'state', 'values', 'unsubscribeAuriniaAlliance') &&
                                    <li>Marketing communications about patient support from Aurinia Alliance<sup>&reg;</sup></li>
                                }
                                {getNested(location,'state', 'values', 'unsubscribeDiseaseAwareness') &&
                                    <li>Disease state awareness information</li>
                                }
                                {getNested(location,'state', 'values', 'unsubscribeCorporate') &&
                                    <li>Corporate communications from Aurinia, the makers of LUPKYNIS</li>
                                }
                            </ul>
                            <Link to="/" className="button">Return to home</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    )
}

export default ResubscribeConfirmed